const header = [
    {
        name: "Cài đặt hệ thống",
        icon: "settings_brightness",
        childs: [
            {
                to: "/agency-manager/clients",
                icon: "admin_panel_settings",
                name: "Quản lý người dùng"
            },
            {
                to: "/agency-manager/prices",
                icon: "add_shopping_cart",
                name: "Cài đặt bảng giá"
            },
            {
                to: "/agency-manager/report",
                icon: "history",
                name: "Nhật ký hoạt động"
            },
            {
                to: "/agency-manager/api",
                icon: "format_shapes",
                name: "Cập nhật API Key"
            }
        ]
    },
    {
        name: "Cài đặt trang",
        icon: "app_settings_alt",
        childs: [
            {
                to: "/agency-manager/website",
                icon: "perm_data_setting",
                name: "Cài đặt Website"
            },
            {
                to: "/agency-manager/layout",
                icon: "app_settings_alt",
                name: "Cài đặt giao diện"
            },
            {
                to: "/agency-manager/payment",
                icon: "account_balance_wallet",
                name: "Cài đặt thanh toán"
            },
            {
                to: "/agency-manager/support",
                icon: "support_agent",
                name: "Cài đặt hỗ trợ"
            },
            {
                to: "/agency-manager/notification",
                icon: "settings",
                name: "Thông báo admin"
            },
            {
                to: "/agency-manager/notification-order",
                icon: "campaign",
                name: "Thông báo tạo đơn"
            }
        ]
    }
]
export default header
